import React from "react"

import Footer from "../components/footer"
import Header from "../components/header"
export default () => {
  return (

  
<div class="min-h-screen bg-white mb-4">
  <Header/>

  <main class="overflow-hidden">

    <div class="bg-gray-50">
      <div class="py-24 lg:py-32 auto-cols-max">
        <div class="relative z-10 mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
          <h1 class="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">Lawn Care</h1>
          <p class="mt-6 text-xl text-gray-500 max-w-6xl">
          At MV Property Care, we offer professional lawn care services within Clarion County, Pennsylvania, and the surrounding areas. 
          </p>
          <p class="mt-6 text-xl text-gray-500 max-w-6xl">
          We talk to the customer and ask what they want. That is the best starting point. Some people want meticulous well kept lawns and some people want the lawn mowed every two weeks. 
          </p>
          <p class="mt-6 text-xl text-gray-500 max-w-6xl">
          We can bill monthly and accept major credit cards. If you have a rental house, hunting camp or summer camp along the river we can mow and keep you informed on your property via: phone call, email, cell phone pic, text and email. If you manage a property (school, hospital, office building or manufacturing plant) that has a maintenance staff for the critical building issues you might want to consider asking M V PROPERTY CARE INC. for a bid on mowing. 
          </p>
          <p class="mt-6 text-xl text-gray-500 max-w-6xl">
          We are a professional company that carries liability insurance with all employees being covered under workers compensation insurance. Many people cut grass for cash. Ask to see an insurance certificate. 
          </p>
          <p class="mt-6 text-xl text-gray-500 max-w-6xl">
          If you hire the guy that just wants a little cash and he has an accident or something flies through the air you didn’t save anything. 
          </p>
        </div>
        
      </div>
      
    </div>

    

  </main>

  <Footer/>
</div>
 
    
  )
}
